import { Injectable, Signal, WritableSignal, signal } from '@angular/core';

export type ToastType = 'success' | 'error' | 'warning';
export interface ToastMessage {
  type: ToastType;
  text: string;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private _message$: WritableSignal<ToastMessage | null> = signal(null);
  message$: Signal<ToastMessage | null> = this._message$.asReadonly();

  show(type: ToastType, text: string) {
    this._message$.set({ type, text });
    // Optionally, auto-dismiss the toast after a timeout
    setTimeout(() => this.clear(), 3000);
  }

  clear() {
    this._message$.set(null);
  }
}
